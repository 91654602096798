<template>
  <div class="px-2">
    <div class="line-blue-currect-time" v-show="false"></div>
    <div class="flex flex-row">
      <div
        class="
          border-0 border-b-0 border-solid border-gray-300
          w-100
          py-3
          text-center
          font-EffraR
        "
      ></div>
      <div class="flex flex-col py-5">
        <span class="font-EffraR text-lg text-gray-600 capitalize">
          {{ _moment()(today).format("ddd") }}
        </span>
        <span
          class="
            h-8
            w-8
            flex
            items-center
            justify-center
            rounded-full
            font-EffraM
            text-xl
          "
          :class="
            _moment()(today).isSame(_moment()(), 'day')
              ? `bg-dokBlue-ultra text-white`
              : `bg-white text-blackdok`
          "
          >{{ _moment()(today).format("DD") }}</span
        >
      </div>
    </div>
    <div
      class="flex flex-row"
      v-for="(hour, index) in genertedHours()"
      :key="index"
    >
      <div class="py-3 px-1 hour-day w-12">
        <div class="time text-sm text-gray-500 font-EffraR">
          {{ _moment()(hour, "HH:mm").format("HH") }}
        </div>
      </div>
      <div
        class="
          border-0 border-t border-r border-l border-solid border-gray-300
          flex-1
          py-0
          px-0
          h-150
          relative
        "
        :aria-label="`${_moment()(today).format('DD/MM/YYYY')} ${hour}`"
      >
        <div
          v-if="
            !blockedHours(
              `${_moment()(today).format('DD/MM/YYYY')} ${hour}`,
              _moment()(today).weekday()
            ).length
          "
          @click="onNewEvent(`${_moment()(today).format('DD/MM/YYYY')} 08:00`)"
          class="bg-isblock bg-opacity-60 absolute bottom-0 top-0 w-full"
        ></div>
        <div
          v-for="mintues in getMintuesinHours(hour)"
          :key="mintues.hour"
          :title="`${_moment()(today).format('DD/MM/YYYY')} ${mintues.hour}`"
        >
          <div
            v-if="
              eventsDay(_moment()(today).format('DD/MM/YYYY'), mintues.hour)
                .length && evetnShow
            "
            :style="`height: ${(150 / 60) * 15}px`"
            class="z-8 relative"
          >
            <itemEvent
              class="flex items-start justify-between w-full px-1"
              v-for="eventItem in eventsDay(
                _moment()(today).format('DD/MM/YYYY'),
                mintues.hour
              )"
              :key="eventItem.id"
              :event="eventItem"
              :start-drag="startDrag"
              :class="
                eventItem.type === 'RESERVED-SLOT' ||
                _moment()(eventItem.startAt).isBefore(new Date())
                  ? 'cursor-default'
                  : 'cursor-move'
              "
            ></itemEvent>
          </div>
          <div
            :style="`height: ${(150 / 60) * 15}px`"
            class="hover:bg-gray-300 flex items-center justify-center z-1 group"
            v-if="
              !eventsDay(_moment()(today).format('DD/MM/YYYY'), mintues.hour)
                .length
            "
            @click="
              onNewEvent(
                `${_moment()(today).format('DD/MM/YYYY')} ${mintues.hour}`
              )
            "
            @drop="
              onDrop(
                $event,
                `${_moment()(today).format('DD/MM/YYYY')} ${mintues.hour}`
              )
            "
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragover.prevent
          >
            <span
              class="font-EffraR hidden group-hover:block text-dokBlue-ultra"
              >{{ mintues.hour }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { find, map, filter } from "lodash";
// Components
const itemEvent = () => import("./items/index");

export default {
  props: {
    events: Array,
    nowDate: Object,
    onNewEvent: Function
  },
  data() {
    return {
      evetnShow: false
    };
  },
  computed: {
    ...mapGetters({
      slots: "agenda/slots"
    }),
    today: {
      get() {
        return this.nowDate;
      },
      set() {
        return;
      }
    }
  },
  created() {
    this.evetnShow = false;
  },
  mounted: function() {
    this.$nextTick(function() {
      this.evetnShow = true;
    });
  },
  methods: {
    ...mapActions("agenda", ["UPDATE_DATE_EVENT"]),
    blockedHours(date, indexDay) {
      let formatDateToMoment = moment(date, "DD/MM/YYYY HH:mm");
      let slotsByIndexDay = find(this.slots, slot => slot.day === indexDay + 1);

      if (!slotsByIndexDay) return false;

      let isBlocked = filter(slotsByIndexDay.slots, slot => {
        const from = moment(slot.from, "HH:mm"),
          to = moment(slot.to, "HH:mm");

        if (formatDateToMoment.isBefore(new Date(), "day")) return;

        return (
          moment(formatDateToMoment.format("HH:mm"), "HH:mm").isBetween(
            from,
            to
          ) || moment(formatDateToMoment.format("HH:mm"), "HH:mm").isSame(from)
        );
      });

      return isBlocked;
    },
    // Generation Hours For Day
    genertedHours() {
      let items = [];
      new Array(16).fill().forEach((acc, index) => {
        items.push(moment({ hour: index + 8 }).format("HH:mm"));
      });

      return items;
    },
    // Get Mintues of hours
    getMintuesinHours(hour) {
      let mintues = [];
      Array(4)
        .fill()
        .filter((x, i) => {
          mintues.push({
            hour: moment(`${hour}:00`, "HH:mm")
              .add(15 * i, "m")
              .format("HH:mm")
          });
        });

      return mintues;
    },
    // Events In Day & Currect Hour
    eventsDay(day, hour) {
      let dateDay = moment(`${day} ${hour}`, "DD/MM/YYYY HH:mm");

      let filterEvents = [];

      map(this.events, event => {
        let dateEvent = moment(event.startAt);

        if (
          dateEvent.isSame(dateDay, "day") &&
          dateEvent.format("HH:mm") === dateDay.format("HH:mm")
        ) {
          filterEvents.push(event);
        }
      });

      return filterEvents;
    },
    // Moment
    _moment() {
      return moment;
    },
    dragEnter(ev) {
      ev.target.classList.add("bg-gray-400");
    },
    dragLeave(ev) {
      ev.target.classList.remove("bg-gray-400");
    },
    startDrag(event, item) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("fromDate", item.startAt);
      event.dataTransfer.setData("eventId", item._id);
    },
    async onDrop(event, toDate) {
      const fromDate = moment(
          event.dataTransfer.getData("fromDate"),
          "DD/MM/YYYY HH:mm"
        ),
        eventId = event.dataTransfer.getData("eventId"),
        toDateFormat = moment(toDate, "DD/MM/YYYY HH:mm");

      if (toDateFormat.isBefore(new Date(), "hour")) return;

      this.$confirm({
        message: `Êtes-vous sûr(e)?`,
        button: {
          no: "Non",
          yes: "Oui"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: async confirm => {
          if (confirm) {
            this.$vs.loading();
            const data = await this.UPDATE_DATE_EVENT({
              id: eventId,
              toDate: toDateFormat
            });

            if (data.ok) {
              let item = find(this.events, ievent => ievent._id === eventId);
              item.startAt = toDateFormat;
              this.$vs.loading.close();
            }
          }
        }
      });

      event.target.classList.remove("bg-gray-400");
    }
  },
  components: { itemEvent }
};
</script>

<style lang="scss">
.hour-day {
  @apply relative;
  .time {
    @apply absolute w-full;
    top: -8px;
    &:after {
      content: " ";
      @apply absolute bg-gray-300;
      margin: 8px 0 1px 15px;
      height: 1px;
      width: 16px;
    }
  }
}

.line-blue-currect-time {
  @apply bg-dokBlue-ultra;
  height: 2px;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    @apply bg-dokBlue-ultra;
  }
}
</style>
